export function throttle(func: () => void, delay: number) {
  let timeout: number | null = null;
  return () => {
    if (timeout) {
      window.clearTimeout(timeout);
    }
    timeout = window.setTimeout(() => {
      timeout = null;
      func();
    }, delay);
  };
}
