import { throttle } from "./js/throttle";

document.querySelectorAll<HTMLElement>(".show-more").forEach((elContainer) => {
  const elBtn =
    elContainer.querySelector<HTMLButtonElement>(".show-more__button");
  const elContent = elContainer.querySelector<HTMLDivElement>(
    ".show-more__content"
  );
  if (!elBtn || !elContent) throw new Error("Could not find button or content");
  const em = Number(elBtn.value);
  if (Number.isNaN(em)) throw new Error("Could not find rows");
  elBtn.addEventListener("click", () => {
    const expand = elBtn.getAttribute("aria-expanded") !== "true";
    elBtn.setAttribute("aria-expanded", expand ? "true" : "false");
    elContainer.setAttribute("data-expanded", expand ? "true" : "false");
    elBtn.title = expand ? "Show Less" : "Show More";
    elContainer.style.maxHeight = expand ? "" : `${em}em`;
  });

  if (elContent.offsetHeight <= elContainer.offsetHeight) {
    elContainer.classList.add("inactive");
  }
});

window.addEventListener(
  "resize",
  throttle(() => {
    document
      .querySelectorAll<HTMLElement>(".show-more")
      .forEach((elContainer) => {
        const elContent = elContainer.querySelector<HTMLDivElement>(
          ".show-more__content"
        );
        if (!elContent) throw new Error("Could not find content");

        if (elContent.offsetHeight <= elContainer.offsetHeight) {
          elContainer.classList.add("inactive");
        } else {
          elContainer.classList.remove("inactive");
        }
      });
  }, 100)
);
